<template>
	<div class="edit-main">
		<div class="data-content">
			<div class="title-text">基本信息</div>
			<div class="form-content flex flex-col-center flex-row-between">
				<el-form ref="form" :model="allData" :rules="rules" label-width="130px" style="width: 600px;">
					<el-form-item label="企业名称">
						<p class="dis-text">{{allData.company}}</p>
					</el-form-item>
					<el-form-item label="用户名称">
						<p class="dis-text">{{allData.username}}</p>
					</el-form-item>
					<el-form-item label="统一社会信用代码">
						<el-input v-model="allData.identification" placeholder="请输入社会信用代码"></el-input>
					</el-form-item>
					<el-form-item label="经济类型">
						<el-select v-model="allData.economics_name" placeholder="请选择经济类型" @change="economicsChange">
						    <el-option v-for="item in economicsType" :key="item.id" :label="item.dict_name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="联系人" prop="truename">
						<el-input v-model="allData.truename"></el-input>
					</el-form-item>
					<el-form-item label="所在地区" prop="address">
						<el-cascader
						    placeholder="选择所在地区" style="width: 180px;"
						    v-model="addressData"
						    :options="addressList"
						    :props="{ label: 'name', value: 'id' }"
						    @change="addressChange"></el-cascader>
					</el-form-item>
					<el-form-item label="所属网格" prop="grid">
						<el-select v-model="allData.grid_name" placeholder="请选择所属网格" @change="gridChange">
						    <el-option v-for="item in gridList" :key="item.id" :label="item.name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否为龙头企业" prop="loginCompany">
						<el-select v-model="longText" placeholder="请选择是否为龙头企业">
						    <el-option v-for="item in longCompany" :key="item.id" :label="item.name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="主营业务" prop="business">
						<el-input v-model="allData.business"></el-input>
					</el-form-item>
					<el-form-item label="企业来源">
						<p class="dis-text">{{allData.source==1?'自主注册':'问诊导入'}}</p>
					</el-form-item>
					<el-form-item label="问诊状态">
						<p class="dis-text" :style="[getColor(allData.diagnose_status)]">{{allData.diagnose_status==1?'待问诊':(allData.diagnose_status==2?'待诊断':'已诊断')}}</p>
					</el-form-item>
				</el-form>
				
				<el-form ref="formRight" :model="allData" :rules="rules" label-width="130px">
					<el-form-item label="企业编号">
						<p class="dis-text">{{allData.company_no}}</p>
					</el-form-item>
					<el-form-item label="自然客户id" prop="three_id">
						<el-input v-model="allData.three_id"></el-input>
					</el-form-item>
					<el-form-item label="企业类型" prop="type_name">
						<el-select v-model="allData.type_name" placeholder="请选择企业类型" @change="typeChange">
						    <el-option v-for="item in companyType" :key="item.id" :label="item.dict_name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="机构类型" prop="institutional_name">
						<el-select v-model="allData.institutional_name" placeholder="请选择机构类型" @change="institChange">
						    <el-option v-for="item in jgType" :key="item.id" :label="item.dict_name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="联系电话" prop="mobile">
						<el-input v-model="allData.mobile"></el-input>
					</el-form-item>
					<el-form-item label="详细地址" prop="addressText">
						<el-input v-model="allData.address"></el-input>
					</el-form-item>
					<el-form-item label="所属行业" prop="industry">
						<el-select v-model="industryText" placeholder="请选择机构类型" @change="industryChange">
						    <el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="人员规模" prop="staff_size">
						<el-select v-model="allData.staff_size" placeholder="请选择人员规模">
						    <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="注册时间">
						<p class="dis-text">{{allData.addtime}}</p>
					</el-form-item>
					<el-form-item label="商企经理" prop="business_manage_name">
						<el-select v-model="allData.business_manage_name" placeholder="请选择商企经理" @change="busChange">
						    <el-option v-for="item in busList" :key="item.id" :label="item.full_name" :value="item.id">
						    </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="认证状态">
						<p class="dis-text" :style="[getAuthColor(allData.vaildate_status)]">{{getAuthStatu(allData.vaildate_status)}}</p>
					</el-form-item>
				</el-form>
			</div>
			<div class="sub-content flex flex-col-center">
				<el-button type="primary" class="sub-btn" @click="enterClick">确定</el-button>
				<el-button @click="exitClick" class="exit-btn">返回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				company_id: '',//企业ID
				rules: {
				  truename: [ { required: true, message: '请输入联系人', trigger: 'blur' } ],
				  address: [ { required: true, message: '请选择所在地区', trigger: 'blur' } ],
				  grid: [ { required: true, message: '请输入活动名称', trigger: 'blur' } ],
				  loginCompany: [ { required: true, message: '', trigger: 'blur' } ],
				  business: [ { required: true, message: '', trigger: 'blur' } ],
				  three_id: [ { required: true, message: '', trigger: 'blur' } ],
				  type_name: [ { required: true, message: '', trigger: 'blur' } ],
				  institutional_name: [ { required: true, message: '', trigger: 'blur' } ],
				  mobile: [ { required: true, message: '', trigger: 'blur' } ],
				  industry: [ { required: true, message: '', trigger: 'blur' } ],
				  staff_size: [ { required: true, message: '', trigger: 'blur' } ],
				  business_manage_name: [ { required: true, message: '', trigger: 'blur' } ]
				},
				allData: {},
				economicsType: [],//经济类型
				gridList: [],//网格
				longCompany: [{name: '是', id: 1}, {name: '否', id: 0}],//是否为龙头企业
				longText: '是', //是否为龙头企业
				addressList: [],//地区所有数据 
				addressData: [],//选择的地区数据
				companyType: [],//企业类型
				jgType: [],//机构类型
				industryText: '',//选择的行业
				industryList: [{name: '制造业', id: 13},{name: '服务业', id: 14},{name: '其他', id: 15}],//行业数据 
				userList: [{name: '0-200人', id: 0},{name: '201-500人', id: 1},{name: '501-1000人', id: 2},{name: '1001-2000人', id: 3},{name: '2000人以上', id: 4}],//人员规模
				busList: [],//商企经理数据 
				economics_id: '',//选择的经济类型
			}
		},
		created() {
			this.company_id = this.$route.query.id;
			this.initData();
			
		},
		methods: {
			//确定
			enterClick() {
				// this.allData.areaid = this.addressData[0].length>0?this.addressData[0][this.addressData[0].length-1]:-1;
				this.allData.areaid = this.addressData;
				this.allData.is_leader = this.longText == '是'?1:0;
				this.$api.saveCompanyInfo(this.allData).then(res=>{
					this.$message({type: 'success', message: '保存成功！'});
					this.exitClick();
				})
			},
			initData() {
				this.$api.companyInfo({company_id: this.company_id}).then(res=>{
					this.allData = res.data;
					this.longText = res.data.is_leader==1?'是':'否';
					this.industryText = res.data.dictionary_type==13?'制造业':(res.data.dictionary_type==14?'服务行业':'其他行业');
					this.economics_id = res.data.economics_id;
					this.addressData = res.data.areaid;
					this.getGrids();
				})
				this.$api.companyGetEconomics().then(res=>{
					this.economicsType = res.data;
				})
				//地区
				this.$api.area_tree_list().then(res => {
					this.addressList = res.data;
				})
				this.$api.getCompanyType().then(res=>{
					this.companyType = res.data;
				})
				this.$api.getInstitutional().then(res=>{
					this.jgType = res.data;
				})
				this.$api.getBusinessManage().then(res=>{
					this.busList = res.data;
				})
			},
			//获取网格数据
			getGrids() {
				this.gridList = [];
				this.$api.business_grid_info(this.addressData[this.addressData.length-1]).then(res=>{
					this.gridList = res.data;
				})
			},
			//地区选择
			addressChange(e) {
				this.addressData = e;
				this.allData.grid_name = '';
				this.getGrids();
			},
			//商企经理
			busChange(e) {
				this.allData.business_manager_id = e;
			},
			//所属行业
			industryChange(e) {
				this.allData.dictionary_type = e;
			},
			//机构类型
			institChange(e) {
				this.allData.institutional_id = e;
			},
			//所属风格
			gridChange(e) {
				this.allData.grid_id = e;
			},
			//经济类型
			economicsChange(e) {
				this.allData.economics_id = e;
			},
			//企业类型
			typeChange(e) {
				this.allData.type_id = e;
			},
			//返回
			exitClick() {
				this.$router.go(-1);
			},
			//诊断状态颜色
			getColor(num) {
				let color = {color: '#04CD83'};
				switch (num){
					case 0:
						color = {color: '#04CD83'};
						break;
					case 1:
						color = {color: '#FD5451'};
						break;
					case 2:
						color = {color: '#1C72F1'};
						break;
					default:
						break;
				}
				return color;
			},
			//认证状态颜色
			getAuthColor(num) {
				let color = {color: '#6C7880'};
				switch (num){
					case 0:
						color = {color: '#FD5451'};
						break;
					case 1:
						color = {color: '#1C72F1'};
						break;
					case 2:
						color = {color: '#04CD83'};
						break;
					case 3:
						color = {color: '#6C7880'};
						break;
					default:
						break;
				}
				return color;
			},
			//获取认证状态
			getAuthStatu(num) {
				let str = '未认证';
				switch (num){
					case 0:
						str = '未认证';
						break;
					case 1:
						str = '审核中';
						break;
					case 2:
						str = '已认证';
						break;
					case 3:
						str = '认证失败';
						break;
					default:
						break;
				}
				return str;
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.edit-main{height: 100%;width: 100%;padding: 18px;box-sizing: border-box;
		.data-content{background-color: #fff;width: 100%;height: 100%;padding: 30px 30px 0 30px;box-sizing: border-box;overflow: scroll;
			.title-text{font-size: 18px;color: #242D33;position: relative;
				&:before{position: absolute;content: '';width: 4px;height: 20px;background: #3984F5;top: 4px;left: -26px;}
				&::after{position: absolute;content: '';height: 1px;width: 95%;background-color: #EDF1F4;right: -10px;top: 50%;}
			}
			.form-content{padding: 40px;
				.dis-text{width: 400px;height: 34px;line-height: 34px;background: #F1F4F7;border-radius: 4px;border: 1px solid #CEDAE0;padding: 0 12px;box-sizing: border-box;}
			}
			.sub-content{margin: -30px 0 0 126px;
				.sub-btn{margin-right: 10px;background: #3984F5;width: 100px;height: 40px;}
				.exit-btn{background: #EEF1F3;width: 100px;height: 40px;}
			}
			
		}
	}
	::v-deep(.el-input__inner) {width: 400px;height: 34px;}
	::v-deep(.el-cascader) {width: 400px !important;}
	::v-deep(.el-form-item) {margin-bottom: 18px;}
	
</style>